<template>
    <v-card flat max-width="500px" class="mx-auto">
        <v-row>
            <v-col cols="12">
                <v-card-title class="pb-0">
                    <v-icon class="mr-1">mdi-file-document-outline</v-icon>
                    Krankenkassenbestätigung
                </v-card-title>
            </v-col>
            <v-col cols="12" align="center" v-if="fetchingContracts">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
            <v-col cols="12" v-else-if="contracts.length === 0 && emailAuth">
                <v-alert type="info">Keine Verträge gefunden</v-alert>
            </v-col>
            <v-col cols="12" v-else-if="emailAuth">
                <v-row no-gutters>
                    <v-col v-if="false" cols="12">
                        <v-alert type="info">
                            Klicke den gewünschten Vertrag an, um die Krankenkassenbestätigung zu erstellen.
                        </v-alert>
                    </v-col>
                    <v-col v-for="c of contracts" :key="'contract-' + c.contractSequenceId" cols="6" class="pa-3">
                        <v-card color="grey darken-4 white--text" align="center">
                            <v-card-title class="justify-center h5">{{ c.membershipName }}</v-card-title>
                            <v-card-subtitle v-if="c.contractType" class="justify-center pb-0 white--text">
                                {{ c.contractType }}
                            </v-card-subtitle>
                            <v-card-text class="pb-0 white--text">
                                <div>
                                    {{ $moment(c.startDate).format('DD.MM.YYYY') }} -
                                    {{ $moment(c.endDate).format('DD.MM.YYYY') }}
                                </div>
                            </v-card-text>
                            <v-card-text class="text-center white--text">
                                <v-chip
                                    v-for="p of c.packages"
                                    class="primary accent-4 white--text mx-1"
                                    :key="p + '-runningTACAdditionalContract'"
                                >
                                    {{ p }}
                                </v-chip>
                            </v-card-text>
                            <v-row no-gutters>
                                <v-col
                                    class="mb-2"
                                    cols="12"
                                    v-for="option of c.options"
                                    :key="option.year + option.startDate + 'contractYear'"
                                >
                                    <v-btn
                                        @click="createPDF(option, c)"
                                        class="mb-2 white--text"
                                        :color="option.isPaid ? 'primary' : option.isRequested ? 'orange' : 'red'"
                                    >
                                        {{ option.year }} Jahr
                                        <v-icon>
                                            {{
                                                option.isPaid
                                                    ? 'mdi-download'
                                                    : option.isRequested
                                                    ? 'mdi-timer-sand'
                                                    : 'mdi-download-lock'
                                            }}
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" v-else>
                <verify-email @emailVerified="verifyEmailHandler"></verify-email>
            </v-col>
        </v-row>
        <v-dialog v-model="pdfDialog" persistent max-width="600px">
            <v-card v-if="selectedContract">
                <v-card-title style="background-color: var(--v-primary)" class="text-h5 px-5 mb-2 white--text">
                    Weitere Informationen
                </v-card-title>
                <v-row class="px-5" no-gutters v-if="!showSignature">
                    <v-col class="pb-0 pt-3" cols="12">
                        <v-text-field
                            label="Name der Krankenversicherung"
                            type="text"
                            class="my-0 py-0"
                            v-model="customerInfos.healthcareName"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="12">
                        <v-text-field
                            label="Zusatzversicherung"
                            type="text"
                            class="my-0 py-0"
                            v-model="customerInfos.additionalInsurance"
                        ></v-text-field>
                    </v-col>

                    <v-col class="py-0" cols="12">
                        <v-text-field
                            label="Versichertennummer"
                            class="my-0 py-0"
                            v-model="customerInfos.insuranceNumber"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="6">
                        <v-text-field
                            label="Vorname"
                            class="my-0 py-0 mr-1"
                            v-model="customerInfos.firstName"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="6">
                        <v-text-field
                            label="Nachname"
                            class="my-0 py-0"
                            v-model="customerInfos.lastName"
                        ></v-text-field>
                    </v-col>

                    <v-col class="py-0" cols="12">
                        <v-text-field label="Strasse" class="my-0 py-0" v-model="customerInfos.street"></v-text-field>
                    </v-col>

                    <v-col class="pt-0" cols="4">
                        <v-text-field
                            type="number"
                            label="PLZ"
                            class="my-0 py-0 mr-1"
                            v-model="customerInfos.zip"
                        ></v-text-field>
                    </v-col>
                    <v-col class="pt-0" cols="8">
                        <v-text-field label="Stadt" class="my-0 py-0" v-model="customerInfos.city"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="px-5" v-else>
                    <v-col cols="12"><v-card-subtitle>Unterschrift</v-card-subtitle></v-col>
                    <v-col cols="12">
                        <signature-pad
                            class="mx-auto"
                            :signature-data="customerInfos.signature"
                            @resetDrawing="resetSignature"
                            @saveSignature="saveSignature"
                            ref="signature"
                        ></signature-pad>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-btn v-if="!showSignature" color="red darken-1" text @click="pdfDialog = false">Abbrechen</v-btn>
                    <v-btn v-else color="red darken-1" text @click="showSignature = false">Zurück</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="showSignature" color="green darken-1" text @click="downloadPDF()">Herunterladen</v-btn>
                    <v-btn v-else color="green darken-1" text @click="showSignature = true">Weiter</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="unlockDialog" persistent max-width="600px">
            <v-card>
                <v-card-title class="text-h5">Bestätigung erforderlich</v-card-title>
                <v-card-text>
                    Die Krankenkassenbestätigung für diesen Vertrag wurde noch nicht von uns freigeschaltet. Möchtest du
                    die Freischaltung beantragen?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="red darken-1" text @click="unlockDialog = false">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="
                            unlockDialog = false
                            sendUnlockRequest()
                        "
                    >
                        Beantragen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showWaitingDialog" persistent max-width="600px">
            <v-card>
                <v-card-title class="text-h5">Bearbeitung läuft</v-card-title>
                <v-card-text>
                    Vielen Dank für deinen Antrag. Wir haben ihn erhalten und bearbeiten ihn so schnell wie möglich. Du
                    wirst per E-Mail benachrichtigt, sobald die Bestätigung in deinem roody Account zum Download
                    bereitsteht.
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-1" text @click="showWaitingDialog = false">Okay</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import api from '@/api'
import signaturePad from '@/components/signaturePad/signaturePad.vue'
import verifyEmail from '@/components/verification/verifyEmail.vue'
export default {
    components: { signaturePad, verifyEmail },
    data() {
        return {
            emailAuth: true,
            showWaitingDialog: false,
            showSignature: false,
            pdfDialog: false,
            unlockDialog: false,
            selectedContract: null,
            selectedContractParent: null,

            contracts: [],
            fetchingContracts: true,
            customerInfos: {
                signature: '',
                firstName: '',
                lastName: '',
                street: '',
                city: '',
                zip: '',
                insuranceNumber: '',
                additionalInsurance: '',
                healthcareName: '',
            },
            user: null,
        }
    },
    created() {
        this.fetchContracts()
        this.fetchUser()
    },
    methods: {
        verifyEmailHandler() {
            this.fetchContracts()
            this.fetchUser()
        },
        resetSignature() {
            this.customerInfos.signature = null
        },
        saveSignature(e) {
            this.customerInfos.signature = e
        },
        async downloadPDF() {
            const { firstName, lastName, street, city, zip, insuranceNumber, additionalInsurance, healthcareName } =
                this.customerInfos

            // Einfache Validierungsbedingungen
            if (!firstName || !lastName || !street || !city || !zip || !insuranceNumber || !healthcareName) {
                this.showSignature = false
                this.$toast.error('Bitte füllen Sie alle erforderlichen Felder aus.')
                return
            }

            this.$refs.signature.saveSignature()
            let contractData = JSON.parse(JSON.stringify(this.selectedContract))

            contractData.membershipName = this.selectedContractParent.membershipName
                .replace(/\d+/g, '')
                .replace('Mitarbeiter', '')

            let data = {
                contract: contractData,
                customerInfos: this.customerInfos,
            }

            data.contract.studioPopulated = this.selectedContractParent.studioPopulated
            data.contract.studio = this.selectedContractParent.studio

            data.contract.packages = data.contract.packages.map((e) => e.name)

            try {
                this.$toast.success('PDF wird erstellt')
                console.log(data)
                await api.createHealthcarePDF(data)
                this.pdfDialog = false
            } catch (e) {
                this.$toast.error('Fehler beim herunterladen, bitte versuche es erneut')
            }
        },
        async sendUnlockRequest() {
            try {
                let status = await api.requestHealthcareUnlock({
                    id: this.selectedContract.contractSequenceId,
                    year: this.selectedContract.year,
                    contract: {
                        studio: this.selectedContractParent.studio,
                        membershipName: this.selectedContractParent.membershipName,
                        startDate: this.selectedContract.startDate,
                        endDate: this.selectedContract.endDate,
                        packages: this.selectedContract.packages,
                    },
                })
                this.unlockDialog = false
                this.fetchContracts()
                this.fetchUser()
                this.showWaitingDialog = true
            } catch (e) {
                this.$toast.error('Ein Fehler ist aufgetreten, bitte versuche es erneut')
            }
        },
        async createPDF(contract, parent) {
            this.showSignature = false
            this.customerInfos.signature = ''
            this.customerInfos.firstName = this.user.vorname || ''
            this.customerInfos.lastName = this.user.nachname || ''
            this.customerInfos.city = this.user.stadt || ''
            this.customerInfos.zip = this.user.plz || ''
            this.customerInfos.street = this.user.adresse_1 || ''
            this.selectedContract = contract
            this.selectedContractParent = parent
            if (contract.isRequested) this.showWaitingDialog = true
            else if (!contract.isPaid) this.unlockDialog = true
            else this.pdfDialog = true
        },
        async fetchContracts() {
            this.fetchingContracts = true

            try {
                let userContracts = await api.healthcareFetchUserContracts()
                this.emailAuth = true
                this.contracts = userContracts
            } catch (e) {
                if (e?.response?.status && e.response.status === 401) {
                    console.log('HERE')
                    this.emailAuth = false
                }
            }

            this.fetchingContracts = false
            console.log(this.contracts)
        },
        async fetchUser() {
            let user = await api.getUser()
            this.user = user.user
        },
    },
    computed: {},
}
</script>

<style lang="scss" scoped>
::v-deep .custom-badge .v-badge__badge {
    transform: scale(1.5); /* Vergrößert den Badge */
}
</style>